import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump2,
  Pipe,
  PipePlaceholder,
  PipeCurve,
  IRCompressor,
  Aftercooler,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  MultiInfo,
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  SystemControl,
  AftercoolerPID,
} from 'isno/lib/components/controls';

import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import PLC from 'isno/lib/static/images/PLC.png';
import { fetchLocation, fetchPLC, setIOValue } from '../actions';
import configuration from './Configuration';

function CompressorScreen(props) {
  const theme = useTheme();
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });
  const { language } = useContext(LanguageContext);

  const { developerRole } = configuration;

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchPLC(6),
        props.fetchPLC(7),
        props.fetchPLC(8),
        props.fetchLocation(2),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchPLC(6);
    props.fetchPLC(7);
    props.fetchPLC(8);
    props.fetchLocation(2);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  const celsius = props.settings?.settings?.useCelsius;
  const IOValueKeys = celsius ? {
    status: 'status',
    speed: 'speed',
    amps: 'current',
    hours: 'run_time',
    fault: 'fault',
    autoManual: 'auto_manual',
    manualSetpoint: 'manual_setpoint',
    autoSetpoint: 'auto_setpoint',
    iGainSetpoint: 'igain_setpoint',
    pGainSetpoint: 'pgain_setpoint',
    autoMode: 'auto_manual',
    manualMode: 'auto_manual',
    blowOffValveOpenedClosed: 'blow_off_valve_opened_closed',
    oilTemperature: 'oil_temperature',
    outletOilPressure: 'outlet_oil_pressure',
    inletOilPressure: 'inlet_oil_pressure',
    pressureSetpoint: 'pressure_setpoint',
    dischargeAirPressure: 'discharge_air_pressure',
    systemAirPressure: 'system_air_pressure',
    stage1Vibration: 'stage_1_vibration',
    stage2Vibration: 'stage_2_vibration',
    stage3Vibration: 'stage_3_vibration',
    stage3Temperature: 'stage_3_inlet_temperature',
  } : {
    status: 'status',
    speed: 'speed',
    amps: 'current',
    hours: 'run_time',
    fault: 'fault',
    autoManual: 'auto_manual',
    manualSetpoint: 'manual_setpoint',
    autoSetpoint: 'auto_setpoint',
    iGainSetpoint: 'igain_setpoint',
    pGainSetpoint: 'pgain_setpoint',
    autoMode: 'auto_manual',
    manualMode: 'auto_manual',
    blowOffValveOpenedClosed: 'blow_off_valve_opened_closed',
    oilTemperature: 'oil_temperature_alternate',
    outletOilPressure: 'outlet_oil_pressure_alternate',
    inletOilPressure: 'inlet_oil_pressure_alternate',
    pressureSetpoint: 'pressure_setpoint_alternate',
    dischargeAirPressure: 'discharge_air_pressure_alternate',
    systemAirPressure: 'system_air_pressure_alternate',
    stage1Vibration: 'stage_1_vibration_alternate',
    stage2Vibration: 'stage_2_vibration_alternate',
    stage3Vibration: 'stage_3_vibration_alternate',
    stage3Temperature: 'stage_3_inlet_temperature_alternate',
  };

  // if (!props.equipment) { return <LoadingScreen />; }
  if (!locationEquipment2) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" border backgroundColor={theme.backgroundColor} label="Compressor Building">
            <InfoControlRow>
              <AftercoolerPID
                title="AC1 PID"
                data={locationEquipment2?.AC1}
                developer={props.auth?.user?.roles && props.auth?.user?.roles.includes(developerRole)}
                pidData={locationEquipment2?.['AC1 PID']}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
              // IOValueKeys={{
              //   autoManual: 'auto_manual',
              //   autoSetpoint: 'auto_setpoint',
              //   manualSetpoint: 'manual_setpoint',
              //   iGainSetpoint: 'igain_setpoint',
              //   pGainSetpoint: 'pgain_setpoint',
              //   autoMode: 'auto_manual',
              //   manualMode: 'auto_manual',
              // }}
                readValues={[0, 1]}
                manPrecision={0}
                autoPrecision={0}
                pGainPrecision={0}
                iGainPrecision={0}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[6])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[6], theme)}
              />
              <Info
                img={PLC}
                title="C1 PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[7])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[7], theme)}
              />
              <Info
                img={PLC}
                title="C2 PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[8])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[8], theme)}
              />
              <AlarmReset
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                buttonText={language.reset}
                title={language.alarmReset}
                resetWriteValue={1}
                buttonHeight="25px"
              />
            </InfoControlRow>
            <PumpsRowCustom height="15px">
              <PipePlaceholder hidden />
              <div css={styles.flexCol}>
                <PipePlaceholder pipeAbove="right" color={theme.pipeColors.air} />
                <div css={css`display: flex; width: 51px; justify-content: center;`}>
                  <Statistic
                    border
                    size="large"
                    label={locationEquipment2?.Other?.ioValues.discharge_air_pressure?.ioValueType?.units}
                    statistic={locationEquipment2?.Other?.ioValues?.discharge_air_pressure?.value}
                    floatLabel="Discharge"
                    floatLabelTop="2px"
                    floatLabelLeft="55px"
                  />
                </div>
              </div>
              <PipePlaceholder capAfter horizontalAbove color={theme.pipeColors.air} />
            </PumpsRowCustom>
            <PumpsRowCustom height="125px">
              <IRCompressor
                greenImg
                controlWidth="100px"
                location={props.locations?.locations?.[2]?.name}
                compressorData={locationEquipment2?.C1}
                pipeTypeAbove={[false, false, false]}
                pipeTypeBelow={[false, true, false]}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2?.C1?.ioValues?.status?.id,
                    label: 'Status',
                    status: locationEquipment2?.C1?.ioValues?.status?.value,
                    statusKey: {
                      0: 'Stop',
                      1: 'Waiting For Auto',
                      2: 'Starting',
                      3: 'Running',
                      4: 'Stopping',
                    },
                    statusColorKey: {
                      0: theme.warning,
                      1: theme.auto,
                      2: theme.offRed,
                      3: theme.onGreen,
                      4: theme.warning,
                    },
                  },
                  {
                    id: locationEquipment2?.C1?.ioValues?.status_alternate?.id,
                    label: 'Ready Status',
                    status: locationEquipment2?.C1?.ioValues?.status_alternate?.value,
                    statusKey: {
                      0: 'Start Prep. Off',
                      1: 'Prep. To Start',
                      2: 'Ready To Run',
                      3: 'Running',
                      4: 'Auto Preparing',
                      5: 'Cooling Down',
                    },
                    statusColorKey: {
                      0: theme.offRed,
                      1: theme.offRed,
                      2: theme.offRed,
                      3: theme.onGreen,
                      4: theme.offRed,
                      5: theme.offRed,
                    },
                  },
                  {
                    id: locationEquipment2?.C1?.ioValues?.loading_status?.id,
                    label: 'Loading Status',
                    status: locationEquipment2?.C1?.ioValues?.loading_status?.value,
                    statusKey: {
                      0: 'Unload',
                      1: 'Loading',
                      2: 'Fix Unload',
                      3: 'Fix Full Load',
                      4: 'Force Unload',
                    },
                    statusColorKey: {
                      0: theme.warning,
                      1: theme.warning,
                      2: theme.warning,
                      3: theme.warning,
                      4: theme.warning,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.C1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.C1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                IOValueKeys={IOValueKeys}
                column1Info={{
                  statistics: [
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.blowOffValveOpenedClosed]?.value,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.oilTemperature]?.value,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.outletOilPressure]?.value,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.inletOilPressure]?.value,
                  ],
                  subtitles: [
                    'Blow Off Valve',
                    'Oil Temp',
                    'Outlet Oil',
                    'Inlet Oil',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    '',
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.oilTemperature]?.ioValueType?.units,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.outletOilPressure]?.ioValueType?.units,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.inletOilPressure]?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.blowOffValveOpenedClosed]?.id,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.oilTemperature]?.id,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.outletOilPressure]?.id,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.inletOilPressure]?.id,
                  ],
                  precisions: [0, 1, 0, 0],
                  mappings: [{ 0: 'Opened', 1: 'Closed' }, undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.dischargeAirPressure]?.value,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.systemAirPressure]?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'Discharge Air',
                    'System Air',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.dischargeAirPressure]?.ioValueType?.units,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.systemAirPressure]?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.dischargeAirPressure]?.id,
                    locationEquipment2?.C1?.ioValues?.[IOValueKeys.systemAirPressure]?.id,
                  ],
                  precisions: [0, 0, 0],
                }}
              />
              <PipePlaceholder color={theme.pipeColors.air} />
              <IRCompressor
                greenImg
                controlWidth="100px"
                location={props.locations?.locations?.[2]?.name}
                compressorData={locationEquipment2?.C2}
                pipeTypeAbove={[false, false, false]}
                pipeTypeBelow={[false, true, false]}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2?.C2?.ioValues?.status?.id,
                    label: 'Status',
                    status: locationEquipment2?.C2?.ioValues?.status?.value,
                    statusKey: {
                      0: 'Stop',
                      1: 'Waiting For Auto',
                      2: 'Starting',
                      3: 'Running',
                      4: 'Stopping',
                    },
                    statusColorKey: {
                      0: theme.warning,
                      1: theme.auto,
                      2: theme.offRed,
                      3: theme.onGreen,
                      4: theme.warning,
                    },
                  },
                  {
                    id: locationEquipment2?.C2?.ioValues?.status_alternate?.id,
                    label: 'Ready Status',
                    status: locationEquipment2?.C2?.ioValues?.status_alternate?.value,
                    statusKey: {
                      0: 'Start Prep. Off',
                      1: 'Prep. To Start',
                      2: 'Ready To Run',
                      3: 'Running',
                      4: 'Auto Preparing',
                      5: 'Cooling Down',
                    },
                    statusColorKey: {
                      0: theme.offRed,
                      1: theme.offRed,
                      2: theme.offRed,
                      3: theme.onGreen,
                      4: theme.offRed,
                      5: theme.offRed,
                    },
                  },
                  {
                    id: locationEquipment2?.C2?.ioValues?.loading_status?.id,
                    label: 'Loading Status',
                    status: locationEquipment2?.C2?.ioValues?.loading_status?.value,
                    statusKey: {
                      0: 'Unload',
                      1: 'Loading',
                      2: 'Fix Unload',
                      3: 'Fix Full Load',
                      4: 'Force Unload',
                    },
                    statusColorKey: {
                      0: theme.warning,
                      1: theme.warning,
                      2: theme.warning,
                      3: theme.warning,
                      4: theme.warning,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.C2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.C2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.C2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.C2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                IOValueKeys={IOValueKeys}
                column1Info={{
                  statistics: [
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.blowOffValveOpenedClosed]?.value,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.oilTemperature]?.value,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.outletOilPressure]?.value,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.inletOilPressure]?.value,
                  ],
                  subtitles: [
                    'Blow Off Valve',
                    'Oil Temp',
                    'Outlet Oil',
                    'Inlet Oil',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    '',
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.oilTemperature]?.ioValueType?.units,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.outletOilPressure]?.ioValueType?.units,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.inletOilPressure]?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.blowOffValveOpenedClosed]?.id,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.oilTemperature]?.id,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.outletOilPressure]?.id,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.inletOilPressure]?.id,
                  ],
                  precisions: [0, 1, 0, 0],
                  mappings: [{ 0: 'Opened', 1: 'Closed' }, undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.dischargeAirPressure]?.value,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.systemAirPressure]?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'Discharge Air',
                    'System Air',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.dischargeAirPressure]?.ioValueType?.units,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.systemAirPressure]?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.dischargeAirPressure]?.id,
                    locationEquipment2?.C2?.ioValues?.[IOValueKeys.systemAirPressure]?.id,
                  ],
                  precisions: [0, 0, 0],
                }}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="130px">
              {/* Curve to connect to compressor above */}
              <div css={css`height: 100%; display: flex; flex: 1; flex-direction: column; justify-content: flex-start; align-items: flex-start;`}>
                <div css={css`
                display: flex;
                margin-left: 15px;
                margin-right: 18px;
                justify-content: space-between;
                width: 61px;
                height: 100%;
              `}
                >
                  <div css={css`min-width: 20px;`} />
                  <Pipe color={theme.pipeColors.air} />
                  <div css={css`min-width: 20px;`} />
                </div>
                <div css={css`height: fit-content; width: 100%;`}>
                  <PipeCurve connect color={theme.pipeColors.air} />
                </div>
              </div>
              {/* Aftercooler goes here */}
              <Aftercooler
                controlPositionTop="-65px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                pipeBelow="middle"
                data={locationEquipment2?.AC1}
                setIOValue={props.setIOValue}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                  2: 'Warning',
                  3: 'Alarm',
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment2.AC1?.ioValues?.status?.id,
                    status: locationEquipment2.AC1?.ioValues?.status?.value,
                    statusKey: {
                      0: 'READY',
                      1: 'RUN',
                      2: 'Switch',
                      3: 'High Temp',
                      4: 'Low Level',
                      5: 'Run Fail',
                      6: 'Disabled',
                      7: 'Comm',
                      8: 'Local',
                      9: 'Fault',
                      10: 'Suction',
                      11: 'LEAD',
                      12: 'E-Stop',
                    },
                    statusColorKey: {
                      0: `${theme.onGreen}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.alarm}`,
                      3: `${theme.alarm}`,
                      4: `${theme.alarm}`,
                      5: `${theme.alarm}`,
                      6: `${theme.alarm}`,
                      7: `${theme.alarm}`,
                      8: `${theme.alarm}`,
                      9: `${theme.alarm}`,
                      10: `${theme.alarm}`,
                      11: `${theme.onGreen}`,
                      12: `${theme.alarm}`,
                    },
                  },
                  {
                    id: locationEquipment2.AC1?.ioValues?.enabled?.id,
                    status: locationEquipment2.AC1?.ioValues?.enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.enabled,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.enabled,
                    },

                  },
                ]}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start_output',
                  stopped: 'stop_start_output',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2.AC1?.ioValues?.[IOValueKeys.fault],
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.AC1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.AC1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.AC1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.AC1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.AC1?.ioValues?.[IOValueKeys.speed]?.value,
                    label: locationEquipment2?.AC1?.ioValues?.[IOValueKeys.speed]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.AC1?.ioValues?.air_temperature?.value,
                    label: locationEquipment2?.AC1?.ioValues?.air_temperature?.ioValueType?.units,
                  },
                ]}
              />
              {/* Curve to connect to compressor above */}
              <div css={css`height: 100%; display: flex; flex: 1; flex-direction: column; justify-content: flex-start; align-items: flex-start;`}>
                <div css={css`
                display: flex;
                margin-left: 15px;
                margin-right: 18px;
                justify-content: space-between;
                width: 61px;
                height: 100%;
              `}
                >
                  <div css={css`min-width: 20px;`} />
                  <Pipe color={theme.pipeColors.air} />
                  <div css={css`min-width: 20px;`} />
                </div>
                <div css={css`display: flex;`}>
                  <div css={css`height: fit-content; width: 100%;`}>
                    <PipeCurve horizontal color={theme.pipeColors.air} />
                  </div>
                  <div css={css`height: fit-content; width: 100%;`}>
                    <PipeCurve left connect color={theme.pipeColors.air} />
                  </div>
                </div>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
          <LabeledContainer height="fit-content" border backgroundColor={theme.backgroundColor} label="Glycol System">
            <PumpsRowCustom height="190px" styles="justify-content: space-evenly;">
              <div css={css`padding-left: 5px;`}>
                <Pump2
                  pipeAbove="none"
                  pipeBelow="none"
                  setIOValue={props.setIOValue}
                  pumpData={{ ...locationEquipment2?.GP1 }}
                  otherData={locationEquipment2?.Other}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start_output',
                    stopped: 'stop_start_output',
                  }}
                  stateKey={{
                    0: 'Off',
                    1: 'On',
                    2: 'Warning',
                    3: 'Alarm',
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  writeValues={[1, 1]}
                  readValues={[1, 0]}
                  extraStatuses={[
                    {
                      label: language.enabled,
                      color: theme.enabled,
                      state: locationEquipment2?.GP1?.ioValues?.enabled?.value,
                      id: locationEquipment2?.GP1?.ioValues?.enabled?.id,
                    },
                  ]}
              // alarms={[]}
                  stats={[
                    {
                      stat: locationEquipment2?.GP1?.ioValues?.run_time?.value,
                      label: locationEquipment2?.GP1?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={css``}>
                <SystemControl
                  title="Glycol Fan Sys. 1"
                  data={locationEquipment2?.['Glycol Fan System 1']}
                  statusKeys={[
                    'fan1_fan2_enabled',
                    'fan3_fan4_enabled',
                    'fan5_fan6_enabled',
                  ]}
                  statusLabels={[
                    'Fan1/Fan2',
                    'Fan3/Fan4',
                    'Fan5/Fan6',
                  ]}
                  statKeys={[
                    'fan1_fan2_run_time',
                    'fan3_fan4_run_time',
                    'fan5_fan6_run_time',
                  ]}
                  statPrecisions={[0, 0, 0]}
                  statLabel="Run Time"
                  setIOValue={props.setIOValue}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start_output',
                    stopped: 'stop_start_output',
                  }}
                  readValues={[1, 0]}
                />
              </div>
              <div css={css`padding-left: 5px;`}>
                <Pump2
                  pipeAbove="none"
                  pipeBelow="none"
                  setIOValue={props.setIOValue}
                  pumpData={{ ...locationEquipment2?.GP2 }}
                  otherData={locationEquipment2?.Other}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start_output',
                    stopped: 'stop_start_output',
                  }}
                  stateKey={{
                    0: 'Off',
                    1: 'On',
                    2: 'Warning',
                    3: 'Alarm',
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  writeValues={[1, 1]}
                  readValues={[1, 0]}
                  extraStatuses={[
                    {
                      label: language.enabled,
                      color: theme.enabled,
                      state: locationEquipment2?.GP2?.ioValues?.enabled?.value,
                      id: locationEquipment2?.GP2?.ioValues?.enabled?.id,
                    },
                  ]}
              // alarms={[]}
                  stats={[
                    {
                      stat: locationEquipment2?.GP2?.ioValues?.run_time?.value,
                      label: locationEquipment2?.GP2?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={css``}>
                <SystemControl
                  title="Glycol Fan Sys. 2"
                  data={locationEquipment2?.['Glycol Fan System 2']}
                  statusKeys={[
                    'fan7_enabled',
                    'fan8_enabled',
                    'fan9_enabled',
                  ]}
                  statusLabels={[
                    'Fan7',
                    'Fan8',
                    'Fan9',
                  ]}
                  statKeys={[
                    'fan7_run_time',
                    'fan8_run_time',
                    'fan9_run_time',
                  ]}
                  statPrecisions={[0, 0, 0]}
                  statLabel="Run Time"
                  setIOValue={props.setIOValue}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start_output',
                    stopped: 'stop_start_output',
                  }}
                  readValues={[1, 0]}
                />
              </div>
              {/* <div css={css`display: flex; flex-direction: column;`}>
                <Info
                  title="Glycol Inlet Temp"
                  statistic={locationEquipment2?.Other?.ioValues?.glycol_inlet_temperature?.value}
                  label={locationEquipment2?.Other?.ioValues?.glycol_inlet_temperature?.ioValueType?.units}
                />
                <Info
                  title="Glycol Outlet Temp"
                  statistic={locationEquipment2?.Other?.ioValues?.glycol_outlet_temperature?.value}
                  label={locationEquipment2?.Other?.ioValues?.glycol_outlet_temperature?.ioValueType?.units}
                />
                <Info
                  title="Glycol Pressure"
                  statistic={locationEquipment2?.Other?.ioValues?.glycol_pressure?.value}
                  label={locationEquipment2?.Other?.ioValues?.glycol_pressure?.ioValueType?.units}
                />
              </div> */}
              <MultiInfo
                title="Glycol Stats"
                subtitles={['Inlet Temp', 'Outlet Temp', 'Pressure']}
                statistics={[
                  locationEquipment2?.Other?.ioValues?.glycol_inlet_temperature?.value,
                  locationEquipment2?.Other?.ioValues?.glycol_outlet_temperature?.value,
                  locationEquipment2?.Other?.ioValues?.glycol_pressure?.value,
                ]}
                labels={[
                  locationEquipment2?.Other?.ioValues?.glycol_inlet_temperature?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.glycol_outlet_temperature?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.glycol_pressure?.ioValueType?.units,
                ]}
                precisions={[1, 1, 0]}
              />
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
      // @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
      //   justify-content: center;
      //   margin: 17px 0px -17px 0px;
      // }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

CompressorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
    }),
  }).isRequired,
};

CompressorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
  auth: state.auth,
});

export default connect(mapStateToProps, { fetchLocation, fetchPLC, setIOValue })(CompressorScreen);
