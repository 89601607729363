import axios from 'axios';
import {
  ActionTypes, JAWG_URI, JAWG_ACCESS_TOKEN,
} from './Shared';

export function fetchElevation(latitude, longitude) {
  return async (dispatch) => {
    try {
    //   const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: JAWG_URI,
        // headers: { Authorization: `Bearer ${token}` },
        params: {
          'access-token': JAWG_ACCESS_TOKEN,
          locations: `${latitude},${longitude}`,
        },
      });
      dispatch({ type: ActionTypes.FETCH_ELEVATION_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ELEVATION_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function fetchAllElevations(timeString1, ids, timeString2) {
  return async (dispatch) => {
    try {
      //
    } catch (error) {
      //
    }
  };
}
