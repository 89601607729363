import { ActionTypes } from '../actions/Shared';

const initialState = {
  mobileGuns: undefined,
  selectedMobileGun: undefined,
  selectedMobileID: null,
  availableMobileGuns: undefined,
};

const MobileGunsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_MOBILE_GUNS_SUCCESS:
      return { ...state, mobileGuns: action.payload };
    case ActionTypes.FETCH_AVAILABLE_MOBILE_GUNS_SUCCESS:
      return { ...state, availableMobileGuns: action.payload };
    case ActionTypes.FETCH_MOBILE_GUN_SUCCESS:
      return {
        ...state,
        selectedMobileGun: action.payload,
      };
    case ActionTypes.ADD_MOBILE_GUN_SUCCESS:
      return {
        ...state,
        mobileGuns: {
          ...state.mobileGuns,
          [action.payload.GunId]: action.payload,
        },
      };
    case ActionTypes.DELETE_MOBILE_GUN_SUCCESS:
      return {
        ...state,
        mobileGuns: {
          ...Object.values(state.mobileGuns).filter((item) => item.gunId !== action.payload.gunId),
        },
      };
    case ActionTypes.EDIT_MOBILE_GUN_SUCCESS:
      return {
        ...state,
        mobileGuns: {
          ...state.mobileGuns,
          [action.payload.id]: {
            ...action.payload,
            gun: state.mobileGuns[action.payload.id].gun,
            equipment: {
              ...action.payload.equipment,
              plc: state.mobileGuns[action.payload.id].equipment?.plc,
            },
          },
        },
      };
    case ActionTypes.SELECT_MOBILE:
      return { ...state, selectedMobileID: action.payload, selectedMobileGun: undefined };
    case ActionTypes.FETCH_MOBILE_GUNS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default MobileGunsReducer;
