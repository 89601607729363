import { ActionTypes } from '../actions/Shared';

const initialState = {
  settings: null,
  gunModels: undefined,
  apiVersion: null,
};

const SettingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_SETTINGS_SUCCESS:
      return { ...state, settings: action.payload };
    case ActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case ActionTypes.GET_API_SUCCESS:
      return {
        ...state,
        apiVersion: action.payload,
      };
    case ActionTypes.SETTINGS_ERROR:
      return { ...state };
    case ActionTypes.FETCH_GUN_MODELS_SUCCESS:
      return { ...state, gunModels: action.payload };
    case ActionTypes.GUN_MODELS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default SettingsReducer;
