// Overview Screen v0.0.2
// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'.
// When updating do all development and testing on isno-www (Quebec Factory)
// and then update the version (v0.x.x) in that before updating this screen.
import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import {
  Map, View, Feature,
} from 'ol';
import {
  Tile as TileLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import TileState from 'ol/TileState';
import { BingMaps, XYZ, Vector as VectorSource } from 'ol/source';
import {
  Point,
} from 'ol/geom';
import * as Proj from 'ol/proj';
import 'ol/ol.css';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import {
  EquipmentStatus,
  FlowMeters,
  FlowVolumes,
  WaterTotals,
  WeatherStatus,
  PressureStatus,
} from 'isno/lib/components/overview';
import {
  fetchAllTrails,
  fetchAllWeatherStations,
  fetchLocations,
} from '../actions';
import configuration from './Configuration';

// Configuration values pulled in from the './Configuration.js' file
const {
  padding, rotationFactor, initialZoom, center, defaultCenterPoint, googleLayerType,
} = configuration;

let hydrantFeatures, hydrants, map, defaultCenterLayer;

const hydrantSource = new VectorSource();
const defaultCenterSource = new VectorSource();

// Function used to load our google maps tiles, we use this instead of the default
// loader to prevents cross site cookie errors, may need to attach additional headers in the future
const customLoader = (tile, src) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.addEventListener('loadend', function load(evt) {
    const data = this.response;
    if (data !== undefined) {
      tile.getImage().src = URL.createObjectURL(data); // eslint-disable-line no-param-reassign
    } else {
      tile.setState(TileState.ERROR);
    }
  });
  xhr.addEventListener('error', () => {
    tile.setState(TileState.ERROR);
  });
  xhr.open('GET', src);
  // xhr.setRequestHeader('foo', 'bar'); // Example of how to set header
  xhr.send();
};

function OverviewScreen(props) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme, { navbarClosed: props.navbarClosed });
  const { language } = useContext(LanguageContext);
  const [gen, setGen] = useState(0);

  useEffect(() => {
    props.fetchAllTrails();
  }, []);

  let shortIntervalId;
  const shortInterval = () => {
    shortIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchAllWeatherStations(),
        props.fetchLocations(),
      ];
      await Promise.all(promises);
      shortIntervalId = setTimeout(shortInterval, 3000);
    }, 3000);
  };

  useEffect(() => {
    props.fetchLocations();
    props.fetchAllWeatherStations();
    shortInterval();
    return () => {
      clearTimeout(shortIntervalId);
    };
  }, []);

  useEffect(() => {
    // We don't want to generate our map until we have data for it
    // NOTE: trails->hydrants has an initial value of null which won't pass this check; however,
    // if we have no hydrants then fetchAllTrails() will set trails->hydrants to {} which will
    // get passed this check so the tracker map can still be genearted.
    if (!props.trails?.hydrants) { return () => {}; }
    if (gen < 1) {
      hydrantFeatures = Object.values(props.trails?.hydrants || {}).map((h) => {
        const c = [h.longitude, h.latitude];
        const p = Proj.fromLonLat(c);
        const g = new Point(p);
        const f = new Feature({
          geometry: g,
        });
        return f;
      });

      if (hydrantFeatures) {
        hydrantSource.clear();
        if (hydrantFeatures.length > 0) {
          hydrantSource.addFeatures(hydrantFeatures);
        }
      }

      if (defaultCenterPoint) {
        defaultCenterSource.addFeatures([new Feature(new Point(Proj.fromLonLat(defaultCenterPoint)))]);
      }

      // Perform initial setup here if map doesn't exist yet, needs to be inside useEffect
      // because otherwise we don't know when props.trails.hydrants will be defined
      // Create our vector layer using our vector source 'hydrantSource'
      hydrants = new VectorLayer({
        name: 'hydrants',
        source: hydrantSource,
        visible: false,
      });

      defaultCenterLayer = new VectorLayer({
        name: 'defaultCenterLayer',
        source: defaultCenterSource,
        visible: false,
      });

      // const url = `https://mt1.google.com/vt/lyrs=${googleLayerType}&hl=pl&&x={x}&y={y}&z={z}`;
      const googleMapsTileSource = new BingMaps({
        key: 'ArKLI8AzT_0s39l0ZvHpJZOuVWnWo1KaIKD3rO5jElzx9DBgHQqquhamhv-2wacT',
        imagerySet: 'Aerial',
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        maxZoom: 19,
        tileLoadFunction: customLoader,
      });

      const tiles = new TileLayer({
        source: googleMapsTileSource,
        preload: 10,
      });

      // new TileLayer({
      //   visible: false,
      //   preload: Infinity,
      //   source: new BingMaps({
      //     key: 'ArKLI8AzT_0s39l0ZvHpJZOuVWnWo1KaIKD3rO5jElzx9DBgHQqquhamhv-2wacT',
      //     imagerySet: Aerial,
      //     // use maxZoom 19 to see stretched tiles instead of the BingMaps
      //     // "no photos at this zoom level" tiles
      //     // maxZoom: 19
      //   }),
      // })

      // Create map with initial setup
      map = new Map({
        target: 'overviewMap',
        layers: [
          tiles,
          defaultCenterLayer,
          ...(hydrants ? [hydrants] : []),
        ],
        view: new View({
          center,
          zoom: initialZoom,
          ...(rotationFactor && {
            rotation: rotationFactor,
          }),
        }),
        controls: [],
        interactions: [],
      });

      if (!hydrantSource.isEmpty()) {
        map.getView().fit(
          hydrantSource.getExtent(),
          {
            size: map.getSize(),
            ...(padding && {
              padding,
            }),
          },
        );
      } else if (!defaultCenterSource.isEmpty()) {
        map.getView().fit(
          defaultCenterSource.getExtent(),
          {
            size: map.getSize(),
            ...(padding && {
              padding,
            }),
          },
        );
      }
      map.getView().setZoom(initialZoom);

      // Handle our map resizing
      window.onresize = () => {
        setTimeout(() => { map.updateSize(); }, 500);
      };
      setGen(1);
    }
    return () => {};
  }, [props.trails?.hydrants]);

  return (
    <div css={styles.background}>
      <div id="overviewMap" css={[styles.map]} />
      <div css={[styles.locationsContainer, styles.hideScrollbar]}>
        <div css={[styles.statuses]}>
          <WaterTotals
            locations={props.locations}
          />
          <FlowMeters
            locations={props.locations}
          />
          <FlowVolumes
            locations={props.locations}
          />
          <EquipmentStatus
            title={language.pumps}
            locations={props.locations}
            equipmentType="pump"
          />
          <EquipmentStatus
            title={language.compressors}
            locations={props.locations}
            equipmentType="compressor"
          />
          <PressureStatus
            locations={props.locations}
          />
          <WeatherStatus
            weatherStations={props.weatherStations?.weatherStations}
            celsius={props.settings?.settings?.useCelsius}
          />
        </div>
        <div css={css`display: flex;`} />
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    background: css`
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
      height: 100%;
    }
  `,
    map: css`
      height: 100vh;
      width: 100%;
      position: relative;
    `,
    statuses: css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-end;
      align-items: flex-start;
      height: fit-content;
      padding: 5px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding: 0px;
        justify-content: center;  
      }
    `,
    locationsContainer: css`
      position: absolute;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      top: 0px;
      right: 0px;
      height: 100%;
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        max-width: calc(100vw - 16px);
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
  };
};

OverviewScreen.propTypes = {
  locations: PropTypes.shape({}).isRequired,
  trails: PropTypes.shape({
    trails: PropTypes.shape({}),
    autotrails: PropTypes.shape({}),
    autotrailsiovalues: PropTypes.shape({}),
    trail: PropTypes.shape({}),
    hydrants: PropTypes.shape({}),
    selectedTrailName: PropTypes.string,
    selectedAutoTrailName: PropTypes.shape({}),
    trackerIOValues: PropTypes.shape({}),
  }).isRequired,

  weatherStations: PropTypes.shape({
    weatherStations: PropTypes.shape({}),
  }).isRequired,

  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,

  fetchAllWeatherStations: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchAllTrails: PropTypes.func.isRequired,
  navbarClosed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  trails: state.trails,
  weatherStations: state.weatherStations,
  settings: state.settings,
  locations: state.locations.locations,
  navbarClosed: state.nav.navbarClosed,
});

export default connect(mapStateToProps, {
  fetchAllWeatherStations,
  fetchLocations,
  fetchAllTrails,
})(OverviewScreen);
