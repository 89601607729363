import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchAllMobileGuns(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/mobileguns/`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_MOBILE_GUNS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_MOBILE_GUNS_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchAvailableMobileGuns(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/mobileguns/available`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_AVAILABLE_MOBILE_GUNS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_MOBILE_GUNS_ERROR, payload: error });
      return error;
    }
  };
}

export function selectMobile(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.SELECT_MOBILE, payload: id });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_MOBILE_GUNS_ERROR, payload: error });
    }
  };
}

export function fetchMobileGun(id, cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/mobileguns/${id}`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_MOBILE_GUN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_MOBILE_GUNS_ERROR, payload: error });
    }
  };
}

export function addMobileGun(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);
      const gunResponse = await axios({
        method: 'post',
        url: `${ROOT_URL}/mobileguns/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_MOBILE_GUN_SUCCESS, payload: gunResponse.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function editMobileGun(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/mobileguns/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.EDIT_MOBILE_GUN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_MOBILE_GUNS_ERROR, payload: error });
    }
  };
}

export function deleteMobileGun(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const gunResponse = await axios({
        method: 'delete',
        url: `${ROOT_URL}/mobileguns/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_MOBILE_GUN_SUCCESS, payload: gunResponse.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}
