import { ActionTypes } from '../actions/Shared';

const initialState = {
  navbarClosed: false,
  navbarStatistics: {},
};

const NavbarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.UPDATE_NAV_STATE:
      return { ...state, navbarClosed: action.payload };
    case ActionTypes.FETCH_ALL_NAV_STATS:
      return { ...state, navbarStatistics: action.payload };
    case ActionTypes.FETCH_NAV_STATS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default NavbarReducer;
