import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchFlowZones(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/zones/flow`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_FLOW_ZONES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchTemperatureZones(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/zones/temperature`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_TEMPERATURE_ZONES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchPressureZones(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/zones/pressure`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_PRESSURE_ZONES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchAllZoneIOValues(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/zoneiovalues`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ZONE_IO_VALUES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error;
    }
  };
}

export function addZoneIOValue(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/zoneiovalues`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_ZONE_IO_VALUE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function editZoneIOValue(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/zoneiovalues/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.EDIT_ZONE_IO_VALUE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function deleteZoneIOValue(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/zoneiovalues/${id}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.DELETE_ZONE_IO_VALUE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error?.response;
    }
  };
}

// Function To Add Zone:
// Type: 'temperature', 'pressure', 'flow'
// Data: {
//   name: 'zone name',
//   baseZoneIOValueLId: '',
//   summitZoneIOValueLId: '',
// }
export function addZone(type, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/zones/${type}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_ZONE_SUCCESS, payload: { type, data: response.data } });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error?.response;
    }
  };
}

// Function To Delete Zone:
// Type: 'temperature', 'pressure', 'flow'
// Data: {
//   name: 'zone name',
//   baseZoneIOValueLId: '',
//   summitZoneIOValueLId: '',
// }
export function deleteZone(type, id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/zones/${type}/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_ZONE_SUCCESS, payload: { type, data: response.data } });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error?.response;
    }
  };
}

// Function To Edit/Update Zone:
// Type: 'temperature', 'pressure', 'flow'
// Data: {
//   name: 'zone name',
//   baseZoneIOValueId: '',
//   summitZoneIOValueId: '',
// }
export function editZone(type, id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/zones/${type}/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_ZONE_SUCCESS, payload: { type, data: response.data } });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_ZONES_ERROR, payload: error });
      return error?.response;
    }
  };
}
