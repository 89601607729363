import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchAllTrails(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trails`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_TRAILS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchAllTrailsWithGeometry(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trails/withGeometry`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_TRAILS_W_GEO_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchAllAutoTrails(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/autotrails`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_AUTOTRAILS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error;
    }
  };
}

export function selectTrail(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.SELECT_TRAIL, payload: id });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
    }
  };
}

export function selectAutoTrail(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.SELECT_AUTO_TRAIL, payload: id });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
    }
  };
}

export function addTrail(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/trails`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_TRAIL_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error.response;
    }
  };
}

export function deleteTrail(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/trails/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_TRAIL_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function updateTrail(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/trails/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.EDIT_TRAIL_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function setTrailState(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/trails/${id}/state`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.EDIT_TRAIL_STATE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function fetchTrail(id) {

}

// just pass in obj with properties (single depth) that I want to update
// Don't need to do unique dispatch, instad use dispatch to refresh targeted hydrant data
// {
//   isEnabled: 1,
// }
export function setTrailValue(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/trails/${id}`,
        data: { ...data },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.FETCH_TRAIL_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
    }
  };
}

export function setAutoTrailValue(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/autotrails/${id}`,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.FETCH_AUTOTRAIL_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
    }
  };
}

export function setAutoTrailIOValue(id, trailId, key, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/autotrailiovalues/${id}`,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.FETCH_AUTOTRAIL_IO_SUCCESS, payload: { ...response.data, trailId, key } });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
    }
  };
}

export function fetchAllAutoTrailsIOValues(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/autotrailiovalues`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_AUTOTRAILS_IO_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_TRAILS_ERROR, payload: error });
      return error;
    }
  };
}
