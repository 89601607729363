import { ActionTypes } from '../actions/Shared';

const initialState = {
  presets: undefined,
};

const QualityReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_PRESETS_SUCCESS:
      return { ...state, presets: action.payload };
    case ActionTypes.FETCH_PRESET_SUCCESS:
      return {
        ...state,
        presets: {
          ...state.presets,
          [action.payload.id]: {
            ...action.payload[action.payload.id],
          },
        },
      };
    case ActionTypes.ADD_PRESET_SUCCESS:
      return {
        ...state,
        presets: {
          ...state.presets,
          [action.payload.id]: {
            ...action.payload[action.payload.id],
          },
        },
      };
    case ActionTypes.DELETE_PRESET_SUCCESS:
      return {
        ...state,
        presets: {
          ...Object.values(state.presets).filter((item) => item.id !== action.payload.id),
        },
      };
    case ActionTypes.UPDATE_PRESET_SUCCESS:
      return {
        ...state,
        presets: {
          ...state.presets,
          [action.payload.id]: {
            ...state.presets?.[action.payload.id],
            ...action.payload,
          },
        },
      };
    case ActionTypes.FETCH_PRESETS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default QualityReducer;
