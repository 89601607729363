import { ActionTypes } from '../actions/Shared';

const initialState = {
  // allEquipment: null,
  // equipmentById: null,
  equipmentByName: null,
  equipment: null,
  valves: null,
};

const EquipmentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_EQUIPMENT_SUCCESS:
      return { ...state, equipment: action.payload };
    case ActionTypes.FETCH_ALL_VALVES_SUCCESS:
      return { ...state, valves: action.payload };
    case ActionTypes.FETCH_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          [action.payload.id]: action.payload,
        },
        valves: {
          ...state.valves,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.FETCH_EQUIPMENT_ERROR:
      return { ...state };
    case ActionTypes.FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        equipmentByName: {
          ...state.equipmentByName,
          ...action.payload.equipment,
        },
      };
    default:
      return state;
  }
};

export default EquipmentReducer;
