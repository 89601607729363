import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  PipePlaceholder,
  Pond,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  PumpsRowMedium,
  PumpsRowCustom,
  InfoControlRow,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Statistic,
  Info,
  Alarms,
  MultiInfo,
  MultiColInfo,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
  InfoButton,
} from 'isno/lib/components/controls';

import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import PLC from 'isno/lib/static/images/PLC.png';
import { fetchLocation, fetchPLC, setIOValue } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow_rate',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  suctionWaterPressure: 'suction_water_pressure',
  // General
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
  autoManual: 'auto_manual',
  manualSetpoint: 'manual_setpoint',
  autoSetpoint: 'auto_setpoint',
  autoMode: 'auto_mode',
  manualMode: 'manual_mode',
};

function Levinsky(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(6),
        props.fetchPLC(2),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchPLC(2);
    props.fetchLocation(6);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'LEAD',
    12: 'E-Stop',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
    12: `${theme.alarm}`,
  };

  const locationEquipment6 = props.locations?.locations?.[6]?.equipment;
  if (!locationEquipment6) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border backgroundColor={theme.backgroundColor} label="Levinsky">
            <InfoControlRow>
              <VFDPumpControl
                title="PID"
                pidData={locationEquipment6?.PID}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={IOValueKeys}
                writeValues={[0, 1]}
              />
              <div css={css`display: flex; flex-direction: column;`}>
                <Info
                  title="PID Fill Mode"
                  statistic={locationEquipment6?.PID?.ioValues?.fill_mode_done?.value === 1 ? 'Done' : 'Filling'}
                  color={locationEquipment6?.PID?.ioValues?.fill_mode_done?.value === 1 ? theme.onGreen : theme.waterColor}
                />
                <Info
                  title="Voltage"
                  statistic={locationEquipment6?.Other?.ioValues?.voltage?.value}
                  label={locationEquipment6?.Other?.ioValues?.voltage?.ioValueType?.units}
                />
              </div>
              <div css={css`display: flex; flex-direction: column;`}>
                <Info
                  title="Snomax"
                  statistic={locationEquipment6?.Other?.ioValues?.snomax_enabled?.value === 1 ? 'Enabled' : 'Disabled'}
                  color={locationEquipment6?.Other?.ioValues?.snomax_enabled?.value === 1 ? theme.onGreen : theme.offRed}
                  alertText={locationEquipment6?.Other?.ioValues?.snomax_low_tank_level?.value === 1 ? 'Low Tank Level' : ''}
                  alertTop="45px"
                  alertLeft="-26px"
                />
                <Info
                  title="Current"
                  statistic={locationEquipment6?.Other?.ioValues?.current?.value}
                  label={locationEquipment6?.Other?.ioValues?.current?.ioValueType?.units}
                />
              </div>
              <div css={css`display: flex; flex-direction: column;`}>
                <Info
                  title="Room Temp"
                  statistic={locationEquipment6?.Other?.ioValues?.room_temperature?.value}
                  label={locationEquipment6?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                  precision={1}
                />
                <Info
                  title="Water Temp"
                  statistic={locationEquipment6?.Other?.ioValues?.water_temperature?.value}
                  label={locationEquipment6?.Other?.ioValues?.water_temperature?.ioValueType?.units}
                  precision={1}
                />
              </div>
              <MultiColInfo
                title="Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment6?.FM?.ioValues?.daily_volume?.value,
                  locationEquipment6?.FM?.ioValues?.monthly_volume?.value,
                  locationEquipment6?.FM?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment6?.FM?.ioValues?.yesterday_volume?.value,
                  locationEquipment6?.FM?.ioValues?.last_month_volume?.value,
                  locationEquipment6?.FM?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment6?.FM?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment6?.FM?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment6?.FM?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment6?.FM?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment6?.FM?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment6?.FM?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment6?.FM?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment6?.FM?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment6?.FM?.ioValues?.daily_volume?.id,
                  locationEquipment6?.FM?.ioValues?.monthly_volume?.id,
                  locationEquipment6?.FM?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment6?.FM?.ioValues?.yesterday_volume?.id,
                  locationEquipment6?.FM?.ioValues?.last_month_volume?.id,
                  locationEquipment6?.FM?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment6?.FM?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[0]}
              />
              <InfoButton
                title="Resettable Volume"
                statistic={locationEquipment6?.FM?.ioValues?.trip_volume?.value}
                label={locationEquipment6?.FM?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment6?.FM?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <AlarmReset
                alarmData={locationEquipment6?.Other}
                setIOValue={props.setIOValue}
                buttonText={language.reset}
                title={language.alarmReset}
                resetWriteValue={1}
                buttonHeight="25px"
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment6?.Other?.ioValues}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
                alertTop="20px"
                alertLeft="-3px"
                alertText={locationEquipment6?.Other?.ioValues?.plc_battery_low?.value ? 'Low Battery' : null}
              />
            </InfoControlRow>
            <PumpsRowMedium>
              <Pump
                percent
                pipeAbove="right"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment6.P4}
                otherData={locationEquipment6.Other}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                  2: 'Warning',
                  3: 'Alarm',
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  speed: 'speed',
                }}
                statuses={[
                  {
                    id: locationEquipment6.P4?.ioValues?.status?.id,
                    status: locationEquipment6.P4?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment6.P4?.ioValues?.auto_enabled?.id,
                    status: locationEquipment6.P4?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment6.P4?.ioValues?.high_seal_temperature,
                  locationEquipment6.P4?.ioValues?.high_motor_temperature,
                  locationEquipment6.P4?.ioValues?.speed_signal_failure,
                  locationEquipment6.P4?.ioValues?.low_suction_pressure_fault,
                  locationEquipment6.P4?.ioValues?.low_suction_pressure,
                  locationEquipment6.P4?.ioValues?.modbus_communication_error,
                  locationEquipment6.P4?.ioValues?.low_water_level_fault,
                  locationEquipment6.P4?.ioValues?.low_water_level,
                  locationEquipment6.P4?.ioValues?.fail_to_run,
                ]}
                stats={[
                  {
                    stat: locationEquipment6?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment6?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment6?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment6?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment6.P3}
                otherData={locationEquipment6.Other}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                  2: 'Warning',
                  3: 'Alarm',
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  speed: 'speed',
                }}
                statuses={[
                  {
                    id: locationEquipment6.P3?.ioValues?.status?.id,
                    status: locationEquipment6.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment6.P3?.ioValues?.auto_enabled?.id,
                    status: locationEquipment6.P3?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment6.P3?.ioValues?.high_seal_temperature,
                  locationEquipment6.P3?.ioValues?.high_motor_temperature,
                  locationEquipment6.P3?.ioValues?.speed_signal_failure,
                  locationEquipment6.P3?.ioValues?.low_suction_pressure_fault,
                  locationEquipment6.P3?.ioValues?.low_suction_pressure,
                  locationEquipment6.P3?.ioValues?.modbus_communication_error,
                  locationEquipment6.P3?.ioValues?.low_water_level_fault,
                  locationEquipment6.P3?.ioValues?.low_water_level,
                  locationEquipment6.P3?.ioValues?.fail_to_run,
                ]}
                stats={[
                  {
                    stat: locationEquipment6?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment6?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment6?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment6?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment6.P2}
                otherData={locationEquipment6.Other}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                  2: 'Warning',
                  3: 'Alarm',
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  speed: 'speed',
                }}
                statuses={[
                  {
                    id: locationEquipment6.P2?.ioValues?.status?.id,
                    status: locationEquipment6.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment6.P2?.ioValues?.auto_enabled?.id,
                    status: locationEquipment6.P2?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment6.P2?.ioValues?.high_seal_temperature,
                  locationEquipment6.P2?.ioValues?.high_motor_temperature,
                  locationEquipment6.P2?.ioValues?.speed_signal_failure,
                  locationEquipment6.P2?.ioValues?.low_suction_pressure_fault,
                  locationEquipment6.P2?.ioValues?.low_suction_pressure,
                  locationEquipment6.P2?.ioValues?.modbus_communication_error,
                  locationEquipment6.P2?.ioValues?.low_water_level_fault,
                  locationEquipment6.P2?.ioValues?.low_water_level,
                  locationEquipment6.P2?.ioValues?.fail_to_run,
                ]}
                stats={[
                  {
                    stat: locationEquipment6?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment6?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment6?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment6?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment6.P1}
                otherData={locationEquipment6.Other}
                stateKey={{
                  0: 'Off',
                  1: 'On',
                  2: 'Warning',
                  3: 'Alarm',
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  speed: 'speed',
                }}
                statuses={[
                  {
                    id: locationEquipment6.P1?.ioValues?.status?.id,
                    status: locationEquipment6.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment6.P1?.ioValues?.auto_enabled?.id,
                    status: locationEquipment6.P1?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment6.P1?.ioValues?.high_seal_temperature,
                  locationEquipment6.P1?.ioValues?.high_motor_temperature,
                  locationEquipment6.P1?.ioValues?.speed_signal_failure,
                  locationEquipment6.P1?.ioValues?.low_suction_pressure_fault,
                  locationEquipment6.P1?.ioValues?.low_suction_pressure,
                  locationEquipment6.P1?.ioValues?.modbus_communication_error,
                  locationEquipment6.P1?.ioValues?.low_water_level_fault,
                  locationEquipment6.P1?.ioValues?.low_water_level,
                  locationEquipment6.P1?.ioValues?.fail_to_run,
                ]}
                stats={[
                  {
                    stat: locationEquipment6?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment6?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment6?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment6?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={styles.flexRow}>
                <PipePlaceholder horizontalAbove color={theme.pipeColors.water} size="large" minWidth="0px" />
                <Statistic
                  border
                  size="large"
                  label={locationEquipment6?.Other?.ioValues.discharge_pressure?.ioValueType?.units}
                  statistic={locationEquipment6?.Other?.ioValues?.discharge_pressure?.value}
                />
                <Statistic
                  border
                  size="large"
                  precision={0}
                  label={locationEquipment6?.Other?.ioValues?.water_flow?.ioValueType?.units}
                  statistic={locationEquipment6?.Other?.ioValues?.water_flow?.value}
                  floatLabel={locationEquipment6?.Other?.ioValues?.live_flow?.value === 1 ? '' : '(Calculated Flow)'}
                  floatLabelTop="25px"
                  floatLabelLeft="-40px"
                />
                <PipePlaceholder horizontalAbove color={theme.pipeColors.water} size="large" minWidth="0px" capAfter capLabel="To R8 Valve House" capLabelTop="-18px" capLabelLeft="-125px" />
              </div>
            </PumpsRowMedium>
            <PumpsRowCustom height="55px">
              <Pond color={theme.pipeColors.water}>
                <Info
                  title="Well Level"
                  statistic={locationEquipment6?.Other?.ioValues?.water_level?.value}
                  label={locationEquipment6?.Other?.ioValues?.water_level?.ioValueType?.units}
                  alertText={locationEquipment6?.Other?.ioValues?.low_water_level?.value ? 'Low Well Level' : null}
                  alertTop="15px"
                  alertLeft="110px"
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: row;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        justify-content: center;
        margin: -25px 0px 15px 0px;
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

Levinsky.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

Levinsky.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, fetchPLC, setIOValue })(Levinsky);
