import { ActionTypes } from '../actions/Shared';

const initialState = {
  forecast: null,
};

const ForecastReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_FORECAST_SUCCESS:
      return { ...state, forecast: action.payload };
    case ActionTypes.FETCH_FORECAST_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default ForecastReducer;
