import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

const queryStringHelper = (ids) => {
  let queryString = '';
  if (ids && ids.length > 0) {
    queryString = '?';
    for (let i = 0; i < ids.length; i++) {
      if (i === 0) {
        queryString += `ids=${ids[i]}`;
      } else {
        queryString += `&ids=${ids[i]}`;
      }
    }
  }
  return queryString;
};

export function fetchTotalGallons(timeString1, ids, timeString2) {
  let timePeriod = timeString1;
  if (timeString2) {
    timePeriod = `${timePeriod}/${timeString2}`;
  }
  return async (dispatch) => {
    const queryString = queryStringHelper(ids);
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trendsstatistic/totalGallons/${timePeriod}${queryString}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_TOTAL_GALLONS_SUCCESS, payload: { ...response.data, id: ids[0] } });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function fetchSnowmakingHours(timeString1, timeString2) {
  let timePeriod = timeString1;
  if (timeString2) {
    timePeriod = `${timePeriod}/${timeString2}`;
  }
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trendsstatistic/snowmakingHours/${timePeriod}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_SNOWMAKING_HOURS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function fetchAverageSei(timeString1, ids, timeString2) {
  let timePeriod = timeString1;
  if (timeString2) {
    timePeriod = `${timePeriod}/${timeString2}`;
  }
  return async (dispatch) => {
    const queryString = queryStringHelper(ids);
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trendsstatistic/averageSei/${timePeriod}${queryString}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_AVERAGE_SEI_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function fetchSnowmakingTrails(timeString1, timeString2) {
  let timePeriod = timeString1;
  if (timeString2) {
    timePeriod = `${timePeriod}/${timeString2}`;
  }
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trendsstatistic/snowmakingTrails/${timePeriod}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_SNOWMAKING_TRAILS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

// Fetch the dataset for a trend
// timeString1:
//             - quickfilter: [15m, 1h, 8h, 24h, 7d, season]
//             or
//             - startDate
// timeString2:
//             - undefined -> if timeString1 is a quick filter
//             or
//             - endDate
// id: ioValueId
export function fetchTrendDataset(timePeriod, id, weatherMetric) {
  // let timePeriod = timeString1;
  // if (timeString2) {
  //   timePeriod = `${timePeriod}/${timeString2}`;
  // }
  const metric = weatherMetric || '';
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/Trendsdataset/${id}/${timePeriod}/${metric}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_TREND_DATASET_SUCCESS, payload: { ...response.data, weatherMetric } });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function fetchTrendDatasetDateRange(timeString1, id, timeString2, weatherMetric) {
  const metric = weatherMetric || '';
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/Trendsdataset/dateRange/${id}/${timeString1}/${timeString2}/${metric}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_TREND_DATASET_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function fetchAllIOValues(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/IOValues`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_IOVALUES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchAllTrends() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trends`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_TRENDS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

// NOTE: THIS IS DEPRECATED, USE AddTrendWithDatasource
export function addTrend(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/trends/add`,
        data: { ...data },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.ADD_TREND_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function deleteTrend(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/trends/${id}/delete`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_TREND_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

// *** depricated *** Kept for reference
// export function addTrendWithIOValue(trendData, ioData) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       // First add the trend, await a response, and then call the first dispatch
//       // to trigger the update to our reducer
//       const trendResponse = await axios({
//         method: 'post',
//         url: `${ROOT_URL}/trends/add`,
//         data: trendData,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       dispatch({ type: ActionTypes.ADD_TREND_SUCCESS, payload: trendResponse.data });

//       const trendIOResponse = await axios({
//         method: 'post',
//         url: `${ROOT_URL}/TrendsIOValue/add`,
//         data: { ...ioData, TrendId: trendResponse?.data?.id },
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       dispatch({ type: ActionTypes.ADD_TRENDIOVALUE_SUCCESS, payload: trendIOResponse.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
//     }
//   };
// }

export function addTrendWithDatasource(trendData, sourceData) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      // First add the trend, await a response, and then call the first dispatch
      // to trigger the update to our reducer
      const trendResponse = await axios({
        method: 'post',
        url: `${ROOT_URL}/trends/add`,
        data: trendData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.ADD_TREND_SUCCESS, payload: trendResponse.data });

      // loop through array of source data and add each
      const trendIOResponses = [];
      for (let i = 0; i < sourceData.length; i++) {
        trendIOResponses.push(axios({
          method: 'post',
          url: `${ROOT_URL}/TrendsDatasource/add`,
          data: { ...sourceData[i], TrendId: trendResponse?.data?.id },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }));
      }
      // Wait for all async promises to resolve then dispatch to reducer
      await Promise.all(trendIOResponses);
      for (let j = 0; j < trendIOResponses.length; j++) {
        dispatch({ type: ActionTypes.ADD_TRENDIOVALUE_SUCCESS, payload: trendIOResponses[j].data });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function updateTrendWithDatasource(trendId, previousSourceData, newSourceData) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      // loop through array of source data and add each
      const trendIOAddResponses = [];
      const trendIODeleteResponses = [];

      // loop through and remove old source data
      for (let i = 0; i < previousSourceData.length; i++) {
        trendIODeleteResponses.push(axios({
          method: 'delete',
          url: `${ROOT_URL}/TrendsDatasource/${previousSourceData[i]?.id}/delete`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }));
      }

      // Loop through and add missing datasources
      for (let i = 0; i < newSourceData.length; i++) {
        trendIOAddResponses.push(axios({
          method: 'post',
          url: `${ROOT_URL}/TrendsDatasource/add`,
          data: { ...newSourceData[i], TrendId: trendId },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }));
      }
      // Wait for all async promises to resolve then dispatch to reducer
      await Promise.all(trendIOAddResponses);
      await Promise.all(trendIODeleteResponses);
      for (let j = 0; j < trendIOAddResponses.length; j++) {
        dispatch({ type: ActionTypes.ADD_TRENDIOVALUE_SUCCESS, payload: trendIOAddResponses[j].data });
      }
      for (let j = 0; j < trendIODeleteResponses.length; j++) {
        dispatch({ type: ActionTypes.DELETE_TRENDIOVALUE_SUCCESS, payload: trendIODeleteResponses[j].data });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function updateTrend(trendId, trendData, trendIOData) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      // First add the trend, await a response, and then call the first dispatch
      // to trigger the update to our reducer
      const trendResponse = await axios({
        method: 'put',
        url: `${ROOT_URL}/trends/${trendId}/update`,
        data: trendData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.UPDATE_TREND_SUCCESS, payload: trendResponse.data });

      // const trendIOResponse = await axios({
      //   method: 'post',
      //   url: `${ROOT_URL}/TrendsDatasource/add`,
      //   data: { ...sourceData, TrendId: trendResponse?.data?.id },
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // dispatch({ type: ActionTypes.ADD_TRENDIOVALUE_SUCCESS, payload: trendIOResponse.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}

export function addTrendIOValue(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/TrendsIOValue/add`,
        data: { ...data },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.ADD_TRENDIOVALUE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.TREND_ERROR, payload: error });
    }
  };
}
