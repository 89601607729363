import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  ValveConnect,
  PipePlaceholder,
  Pipe,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Alarms,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import PLC from 'isno/lib/static/images/PLC.png';
import { fetchLocation, fetchPLC, setIOValue } from '../actions';

function R8ValveScreen(props) {
  const theme = useTheme();
  // const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(4),
        props.fetchPLC(4),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchPLC(4);
    props.fetchLocation(4);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const locationEquipment4 = props.locations?.locations?.[4]?.equipment;
  if (!locationEquipment4) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <InfoControlRow>
            <Info
              img={PLC}
              title="PLC Status"
              statistic={PLCStateRouter(props.plcs?.plcs?.[4])}
              label=""
              color={PLCColorRouter(props.plcs?.plcs?.[4], theme)}
              alertTop="20px"
              alertLeft="-3px"
              alertText={locationEquipment4?.Other?.ioValues?.plc_fault?.value === 1 ? `Fault: (Code: ${locationEquipment4?.Other?.ioValues?.plc_fault_code?.value})` : null}
            />
            <Info
              title="PLC Run Timer"
              statistic={locationEquipment4?.Other?.ioValues?.plc_run_timer?.value}
              label={locationEquipment4?.Other?.ioValues?.plc_run_timer?.ioValueType?.units}
              precision={1}
            />
            <Alarms
              title="General Alarms"
              ioValues={locationEquipment4?.Other?.ioValues}
              height={80}
            />
          </InfoControlRow>
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="RM 8 Air Manifold">
            <PumpsRowCustom height="135px">
              <ValveConnect
                pipeBelow="right"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV15, name: 'BV-15' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt12_silver_knife_air_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt12_silver_knife_air_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Silver Knife"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
                )}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV14, name: 'BV-14' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt11_tippys_air_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt11_tippys_air_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Tippy's"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
                )}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV12, name: 'BV-12' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt9_ambush_air_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt9_ambush_air_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Ambush"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
                )}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV18, name: 'BV-18' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt18_future_air_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt18_future_air_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Future"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
                )}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV16, name: 'BV-16' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt13_middle_ambush_air_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt13_middle_ambush_air_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Ambush Middle"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
                )}
              />
              <div css={css`display: flex; flex-direction: column; height: 100%; width: 100px;`}>
                <div css={css`display: flex; flex-direction: column; height: 100%;`}>
                  <Pipe capBefore capLabel="From Compressors" capLabelTop="82px" capLabelLeft="-1px" color={theme.pipeColors.air} />
                  <div css={styles.statMobile}>
                    <Statistic
                      border
                      float
                      floatColor={theme.pipeColors.air}
                      statistic={locationEquipment4?.Other?.ioValues?.pt14_header_air_pressure?.value}
                      label={locationEquipment4?.Other?.ioValues?.pt14_header_air_pressure?.ioValueType?.units}
                      floatLabelStyle={`display: none; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {display: inherit;}`}
                      floatLabel="From Compressors"
                      floatLabelTop="1px"
                      floatLabelLeft="-133px"
                    />
                  </div>
                  <Pipe color={theme.pipeColors.air} />
                </div>
                <Pipe horizontal color={theme.pipeColors.air} />
              </div>
              <ValveConnect
                pipeBelow="left"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV13, name: 'BV-13' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt10_mr_k_air_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt10_mr_k_air_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Mr. K"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
            </PumpsRowCustom>
          </LabeledContainer>
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="RM 8 Water Manifold">
            <PumpsRowCustom height="140px">
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV5, name: 'DV-05' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt5_silver_knife_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt5_silver_knife_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Silver Knife"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV4, name: 'DV-04' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt4_tippys_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt4_tippys_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Tippy's"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV2, name: 'DV-02' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt2_ambush_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt2_ambush_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Ambush"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV17, name: 'DV-17' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt17_future_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt17_future_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Future"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV6, name: 'DV-06' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt6_middle_ambush_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt6_middle_ambush_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Ambush Middle"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV1, name: 'DV-01' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt7_compressor_building_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt7_compressor_building_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.water}
                    floatLabel="Compressors"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
              <ValveConnect
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.DV3, name: 'DV-03' }}
                setIOValue={props.setIOValue}
                childBefore={(
                  <Statistic
                    float
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt3_mr_k_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt3_mr_k_water_pressure?.ioValueType?.units}
                    floatColor={theme.pipeColors.air}
                    floatLabel="Mr. K"
                    floatLabelTop="1px"
                    floatLabelLeft="27px"
                  />
            )}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="90px">
              <ValveConnect
                pipeBelow="right"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV5, name: 'BV-05' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV4, name: 'BV-04' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV2, name: 'BV-02' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV17, name: 'BV-17' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV6, name: 'BV-06' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                pipeBelow="middle"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV7, name: 'BV-07' }}
                setIOValue={props.setIOValue}
              />
              <ValveConnect
                pipeBelow="left"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV3, name: 'BV-03' }}
                setIOValue={props.setIOValue}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="120px">
              <div css={css`flex: 1; display: flex; flex-direction: row; height: 100%;`}>
                <PipePlaceholder capBefore capLabel="Levinsky Supply" capLabelTop="-24px" capLabelLeft="39px" horizontalBelow size="large" color={theme.pipeColors.water} />
                <div css={css`display: flex; justify-content: flex-end; flex-direction: column;`}>
                  <Statistic
                    size="large"
                    border
                    precision={0}
                    statistic={locationEquipment4?.Other?.ioValues?.pt1_supply_water_pressure?.value}
                    label={locationEquipment4?.Other?.ioValues?.pt1_supply_water_pressure?.ioValueType?.units}
                    floatLabelStyle={`display: none; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {display: inherit;}`}
                    floatLabel="Levinsky Supply"
                    floatLabelTop="2px"
                    floatLabelLeft="46px"
                  />
                </div>
                <PipePlaceholder size="large" horizontalBelow color={theme.pipeColors.water} />
              </div>
              <ValveConnect
                pipeBelow="left"
                pipeSizeBelow="large"
                pipeColorAbove={theme.pipeColors.water}
                pipeColorBelow={theme.pipeColors.water}
                binary
                percent
                controls
                controlsLeft="45px"
                percentTop="46px"
                percentLeft="-15px"
                nameTop="67px"
                nameLeft="-14px"
                nameWidth="60px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'In Travel',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                  open: 'open',
                  close: 'close',
                }}
                greenImageKey="Opened"
                yellowImageKey="In Travel"
                valveData={{ ...locationEquipment4?.BV1, name: 'BV-01' }}
                setIOValue={props.setIOValue}
              />

            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    statMobile: css`
      display: flex;
      justify-content: center;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin: 5px 0px 5px 63px;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

R8ValveScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

R8ValveScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, fetchPLC, setIOValue })(R8ValveScreen);
