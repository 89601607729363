import { ActionTypes } from '../actions/Shared';

const initialState = {
  hydrants: null,
  hydrantModels: null,
  hydrant: null,
  selectedHydrantId: null,
  notes: null,
  note: null,
  equipment: null,
  globalNotes: null,
};

// return { ...state, equipment: action.payload, allEquipment: { ...state.allEquipment, [action.payload.id]: action.payload } };
function HydrantsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_HYDRANT_MODELS_SUCCESS:
      return { ...state, hydrantModels: action.payload };
    case ActionTypes.FETCH_ALL_HYDRANTS_SUCCESS:
      return { ...state, hydrants: action.payload };
    case ActionTypes.FETCH_HYDRANT_SUCCESS:
      // return {
      //   ...state,
      //   hydrant: action.payload,
      //   hydrants: {
      //     ...state.hydrants,
      //     [action.payload.id]: action.payload,
      //   },
      // };

      // I should redo this without the Object.assign() hack
      // Should match the cleaner style used below for the NOTE_ADD_CHANGE_SUCCESS that addresses the
      // depth copying issue with a nested object format
      return {
        ...state,
        // equipment: state.hydrants?.[action.payload.id]?.equipment,
        hydrant: action.payload,
        // hydrants: Object.assign(
        //   state.hydrants,
        //   {
        //     [action.payload.id]: {
        //       ...action.payload,
        //       hasAlarm: state.hydrants?.[action.payload.id]?.hasAlarm,
        //       controlMode: state.hydrants?.[action.payload.id]?.controlMode,
        //       equipment: state.hydrants?.[action.payload.id]?.equipment,
        //       trail: state.hydrants?.[action.payload.id]?.trail,
        //       trailName: state.hydrants?.[action.payload.id]?.trailName,
        //       gunModel: state.hydrants?.[action.payload.id]?.gunModel,
        //       notes: state.hydrants?.[action.payload.id]?.notes,
        //       // notes: Object.assign(state.notes, { ...state.hydrants?.[action.payload.id]?.notes, ...state.notes }),
        //     },
        //   },
        // ),
      };
    case ActionTypes.FETCH_HYDRANT_IO_SUCCESS:
      return {
        ...state,
        hydrants: {
          ...state.hydrants,
          [action.payload.hydrantId]: {
            ...state.hydrants?.[action.payload.hydrantId],
            ...action.payload,
          },
        },
      };
    case ActionTypes.ADD_HYDRANT_SUCCESS:
      return {
        ...state,
        equipment: state.hydrants?.[action.payload.id]?.equipment,
        hydrant: action.payload,
        hydrants: {
          ...state.hydrants,
          [action.payload.id]: {
            ...state.hydrants?.[action.payload.id],
            ...action.payload,
          },
        },
      };
      // case ActionTypes.DELETE_HYDRANT_SUCCESS:
      //   return { ...state, hydrant: null, hydrants: { ...Object.values(state.hydrants).filter((item) => item.id !== action.payload.id) } };

    case ActionTypes.EDIT_HYDRANT_SUCCESS:
      return {
        ...state,
        equipment: state.hydrants?.[action.payload.id]?.equipment,
        hydrant: action.payload,
        hydrants: {
          ...state.hydrants,
          [action.payload.id]: {
            ...state.hydrants?.[action.payload.id],
            ...action.payload,
          },
        },
      };

    case ActionTypes.NOTE_ADD_CHANGE_SUCCESS:
      return {
        ...state,
        hydrant: {
          ...state.hydrant,
          notes: {
            ...state.hydrant?.notes,
            ...(action.payload.hydrantId === state.hydrant?.id && {
              [action.payload.id]: {
                ...action.payload,
                user: {
                  ...(action.payload.user === null
                    ? state.hydrant?.notes?.[action.payload.id]?.user
                    : action.payload.user
                  ),
                },
              },
            }),
          },
        },
        globalNotes: {
          ...state.globalNotes,
          ...(action.payload.hydrantId === null && {
            [action.payload.id]: {
              ...action.payload,
              user: {
                ...(action.payload.user === null
                  ? state.globalNotes?.[action.payload.id]?.user
                  : action.payload.user
                ),
              },
            },
          }),
        },
      };
    case ActionTypes.FETCH_HYDRANT_ERROR:
      return { ...state };
    case ActionTypes.SELECT_HYDRANT:
      return { ...state, selectedHydrantId: action.payload };
    case ActionTypes.FETCH_GLOBAL_NOTES_SUCCESS:
      return { ...state, globalNotes: action.payload };
    default:
      return state;
  }
}

export default HydrantsReducer;
