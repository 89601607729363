import { ActionTypes } from '../actions/Shared';

const initialState = {
  snowDepths: null,
};

// return { ...state, equipment: action.payload, allEquipment: { ...state.allEquipment, [action.payload.id]: action.payload } };
const SnowDepthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_SNOW_DEPTHS_SUCCESS:
      return { ...state, snowDepths: action.payload };
    case ActionTypes.FETCH_SNOW_DEPTHS_SUCCESS:
      return { ...state, snowDepths: action.payload };
    case ActionTypes.SNOW_DEPTH_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default SnowDepthReducer;
