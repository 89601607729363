import { ActionTypes } from '../actions';

const initialState = {
  user: {},
  users: null,
  token: null,
  authenticated: false,
  need2fa: false,
  error: null,
  features: null,
};

function AuthReducer(state = initialState, action = {}) {
  const usersObj = {};
  if (action.type === ActionTypes.FETCH_ALL_USERS_SUCCESS) {
    // Build a users obj
    const tempUsers = Object.values(action.payload || {});
    for (let i = 0; i < tempUsers.length; i++) {
      usersObj[tempUsers[i].id] = tempUsers[i];
    }
  }
  switch (action.type) {
    case ActionTypes.FETCH_FEATURES_SUCCESS:
      return { ...state, features: action.payload };
    case ActionTypes.FETCH_FEATURES_ERROR:
      return { ...state };
    case ActionTypes.FETCH_ALL_USERS_SUCCESS:
      return { ...state, users: usersObj };
    case ActionTypes.FETCH_ALL_USERS_ERROR:
      return { ...state };
    case ActionTypes.AUTH_USER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token ? action.payload.token : state.token,
        authenticated: true,
        need2fa: false,
        error: null,
      };
    case ActionTypes.AUTH_NEED_2FA:
      return {
        ...state,
        user: action.payload.user,
        token: null,
        authenticated: false,
        need2fa: true,
        error: null,

      };
    case ActionTypes.RESET_2FA:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.user.id]: action.payload.user,
        },
        error: null,

      };
    case ActionTypes.SET_IS_DISABLED:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.user.id]: {
            ...state.users?.[action.payload.user.id],
            isDisabled: action.payload.user.isDisabled,
          },
        },
        error: null,
      };
    case ActionTypes.SET_EMAIL:
      return {
        ...state,
        user: action.payload.user,
        error: null,
      };
    case ActionTypes.DEAUTH_USER:
      return {
        user: {},
        token: null,
        authenticated: false,
        need2fa: false,
        error: null,
        tokenExpired: false,
      };
    case ActionTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.user.id]: {
            ...action.payload.user,
          },
        },
      };
    case ActionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users?.[action.payload.id],
            ...action.payload,
          },
        },
      };
    case ActionTypes.DEAUTH_USER_AUTO:
      return {
        user: {},
        token: null,
        authenticated: false,
        need2fa: false,
        error: null,
        tokenExpired: true,
      };
    case ActionTypes.AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        error: action.payload,
      };
    case ActionTypes.RESET_2FA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.SET_IS_DISABLED_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.ADD_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default AuthReducer;
