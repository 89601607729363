import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function getCurrentAuth() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/users/me`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.username) {
        dispatch({ type: ActionTypes.AUTH_USER, payload: { user: response.data } });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: error?.response?.status });
    }
  };
}

export function signinUser(params) {
  const { username, password, twoFactorCode } = params;

  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/users/authenticate`,
        data: { username, password, twoFactorCode },
      });

      if (response.status === 202) {
        dispatch({ type: ActionTypes.AUTH_NEED_2FA, payload: { user: response.data } });
      } else {
        localStorage.setItem(TOKEN_STRING, response.data.token);
        dispatch({ type: ActionTypes.AUTH_USER, payload: { user: response.data, token: response.data.token } });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: error?.response?.status });
    }
  };
}

export function fetchFeatures(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/features/statuses`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_FEATURES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_FEATURES_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchAllUsers(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/users`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_USERS_SUCCESS, payload: { ...response.data } });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_ALL_USERS_ERROR, payload: error });
      return error;
    }
  };
}

export function reset2FA(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/users/${id}/reset2FA`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.username) {
        dispatch({ type: ActionTypes.RESET_2FA, payload: { user: response.data } });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.RESET_2FA_ERROR, payload: error?.response?.status });
    }
  };
}

export function setIsDisabled(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/users/${id}/setIsDisabled`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });

      if (response.data.username) {
        dispatch({ type: ActionTypes.SET_IS_DISABLED, payload: { user: response.data } });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.SET_IS_DISABLED_ERROR, payload: error?.response?.status });
    }
  };
}

export function addUser(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/users/add`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_USER_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.ADD_USER_ERROR, payload: error?.response?.status });
      return error.response;
    }
  };
}

export function editUser(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/users/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.EDIT_USER_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.ADD_USER_ERROR, payload: error });
      return error.response;
    }
  };
}

export function setEmail(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/users/${id}/setEmail`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });

      if (response.data.username) {
        dispatch({ type: ActionTypes.SET_EMAIL, payload: { user: response.data } });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.SET_EMAIL_ERROR, payload: error?.response?.status });
    }
  };
}

export function signoutUser() {
  localStorage.removeItem(TOKEN_STRING);
  return (dispatch) => {
    dispatch({ type: ActionTypes.DEAUTH_USER });
  };
}

export function signoutUserAuto() {
  localStorage.removeItem(TOKEN_STRING);
  return (dispatch) => {
    dispatch({ type: ActionTypes.DEAUTH_USER_AUTO });
  };
}

// Parse token from base64 encoding
function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

// Function to fetch the JWT token from local storage and check if it is expired or not.
export function jwtTokenIsExpired() {
  const token = localStorage.getItem(TOKEN_STRING);
  if (token) {
    const decodedJwt = parseJwt(token);
    // If our expiration date is great than the current date e.g hasn't happened yet,
    // then our token isn't expired.
    if ((decodedJwt.exp * 1000) > Date.now()) {
      return false;
    }
  }
  return true;
}
