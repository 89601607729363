import { ActionTypes } from '../actions/Shared';

const initialState = {
  gateways: undefined,
  nodes: undefined,
};

const GatewaysReducer = (state = initialState, action = {}) => {
  let arrayNodes;
  if (action.type === ActionTypes.FETCH_NODES_SUCCESS) {
    // Build a hydrants obj by pulling the hydrants from our trails route
    arrayNodes = Object.entries(action.payload || {});
  }
  switch (action.type) {
    case ActionTypes.FETCH_ALL_GATEWAYS_SUCCESS:
      return { ...state, gateways: action.payload };
    case ActionTypes.FETCH_GATEWAY_SUCCESS:
      return {
        ...state,
        gateways: {
          ...state.gateways,
          [action.payload.id]: {
            ...action.payload[action.payload.id],
          },
        },
      };
    case ActionTypes.ADD_GATEWAY_SUCCESS:
      return {
        ...state,
        gateways: {
          ...state.gateways,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    case ActionTypes.DELETE_GATEWAY_SUCCESS:
      return {
        ...state,
        gateways: {
          ...Object.values(state.gateways).filter((item) => item.id !== action.payload.id),
        },
      };
    case ActionTypes.FETCH_NODES_SUCCESS:
      return {
        ...state,
        nodes: {
          ...Object.values(arrayNodes || {}).map((node) => {
            return {
              [node[0]]: {
                ...node[1],
                mac: node[0],
              },
            };
          }).reduce((acc, curr) => {
            return { ...acc, ...curr };
          }, {}),
        },
      };
    case ActionTypes.ADD_NODE_SUCCESS:
      return {
        ...state,
      };
    case ActionTypes.DELETE_NODE_SUCCESS:
      return {
        ...state,
        nodes: {
          ...Object.values(state.nodes).filter((item) => item.name !== action.payload),
        },
      };
    case ActionTypes.FETCH_GATEWAYS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default GatewaysReducer;
