import { ActionTypes } from '../actions/Shared';

const initialState = {
  locations: {},
  alarms: [],
};

const LocationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_LOCATIONS_SUCCESS:
      return { ...state, locations: action.payload };
    case ActionTypes.FETCH_ALARMS_SUCCESS:
      return { ...state, alarms: action.payload };
    case ActionTypes.FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.FETCH_LOCATION_ERROR:
      return { ...state };
    case ActionTypes.FETCH_ALARMS_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default LocationReducer;
