import { ActionTypes } from '../actions/Shared';

const initialState = {
  weatherStations: null,
};

const WeatherStationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_WEATHER_STATIONS_SUCCESS:
      return { ...state, weatherStations: action.payload };
    case ActionTypes.FETCH_WEATHER_STATION_SUCCESS:
      return {
        ...state,
        weatherStations: {
          ...state.weatherStations,
          [action.payload.id]: {
            ...state.weatherStations[action.payload.id],
            ...action.payload,
          },
        },
      };
    case ActionTypes.FETCH_WEATHER_STATION_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default WeatherStationReducer;
