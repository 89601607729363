import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function setOtherNavState(newState) {
  return (dispatch) => {
    // dispatch({ type: ActionTypes.UPDATE_NAV_STATE, payload: newState });
  };
}

export function setNavState(newState) {
  return (dispatch) => {
    // console.log(`newState: ${newState}`);
    dispatch({ type: ActionTypes.UPDATE_NAV_STATE, payload: newState });
  };
}

export function fetchNavBarStats() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/navbarstatistic`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_ALL_NAV_STATS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_NAV_STATS_ERROR, payload: error });
    }
  };
}
