import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchSnowDepthsByDate(startDate, endDate) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/snowdepth/dateRange/${startDate}/${endDate}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_SNOW_DEPTHS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      // if (axios.isCancel(error)) {
      //   return error.message;
      // }
      dispatch({ type: ActionTypes.SNOW_DEPTH_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchSnowDepths(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/snowdepth/`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_SNOW_DEPTHS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.SNOW_DEPTH_ERROR, payload: error });
      return error;
    }
  };
}
