import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchAllNotes() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/notes/`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_ALL_NOTES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_NOTES_ERROR, payload: error });
    }
  };
}

export function fetchGlobalNotes(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/notes/global`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_GLOBAL_NOTES_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_NOTES_ERROR, payload: error });
      return error;
    }
  };
}

// export function fetchNote(id) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       const response = await axios({
//         method: 'get',
//         url: `${ROOT_URL}/notes/${id}`,
//         headers: { Authorization: `Bearer ${token}` },

//       });
//       dispatch({ type: ActionTypes.FETCH_HYDRANT_SUCCESS, payload: response.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.FETCH_HYDRANT_SUCCESS, payload: error });
//     }
//   };
// }

// just pass in obj with properties (single depth) that I want to update
// Will also need NOTE ID
// {
//   text: 1,
// }
// export function setNoteText(note) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       // dispatch({ type: ActionTypes.UPDATE_PUMP_LOADING, message: `Setting Pump id: ${id} to output: ${output}%` });
//       const response = await axios({
//         method: 'put',
//         url: `${ROOT_URL}/notes/${note.id}`,
//         data: { note },
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       dispatch({ type: ActionTypes.FETCH_NOTE_SUCCESS, payload: response.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.FETCH_NOTE_SUCCESS, payload: error });
//     }
//   };
// }
export function updateNote(noteId, HydrantId, Text, Severity, isAcknowledged) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      // dispatch({ type: ActionTypes.UPDATE_PUMP_LOADING, message: `Setting Pump id: ${id} to output: ${output}%` });
      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/notes/${noteId}/update`,
        data: {
          HydrantId, Text, Severity, isAcknowledged,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.NOTE_ADD_CHANGE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function addNote(data, user) {
  // console.log(data);
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      // dispatch({ type: ActionTypes.UPDATE_PUMP_LOADING, message: `Setting Pump id: ${id} to output: ${output}%` });
      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/notes/add`,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.NOTE_ADD_CHANGE_SUCCESS, payload: { ...response.data, user } });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

// export function setIOValue(id, value) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       // dispatch({ type: ActionTypes.UPDATE_PUMP_LOADING, message: `Setting Pump id: ${id} to output: ${output}%` });
//       const response = await axios({
//         method: 'put',
//         url: `${ROOT_URL}/iovalues/${id}/value`,
//         data: { value },
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       dispatch({ type: ActionTypes.FETCH_EQUIPMENT_SUCCESS, payload: response.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.FETCH_EQUIPMENT_ERROR, payload: error });
//     }
//   };
// }
